

@font-face {
  font-family: 'Woonconnect';
  src: url('Wct-icons/icons.eot');
  src: url('Wct-icons/icons.eot?#iefix') format('embedded-opentype'),
       url('Wct-icons/icons.woff2') format('woff2'),
       url('Wct-icons/icons.woff') format('woff'),
       url('Wct-icons/icons.ttf') format('truetype'),
       url('Wct-icons/icons.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}




.wct-icon {
    display: inline-block;
    font: normal normal normal 14px/1 'Woonconnect';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


  .wct-icon-lg {
      font-size: 1.33333333em;
      line-height: 0.75em;
      vertical-align: -15%;
  }

  .wct-icon-2x {
      font-size: 2em;
  }

  .wct-icon-3x {
      font-size: 3em;
  }

  .wct-icon-4x {
      font-size: 4em;
  }

  .wct-icon-5x {
      font-size: 5em;
  }



  .wct-icon-fw {
      width: 1.28571429em;
      text-align: center;
  }



  .wct-icon-ul {
      padding-left: 0;
      margin-left: 2.14285714em;
      list-style-type: none;
  }

  .wct-icon-ul> li {
      position: relative;
  }

  .wct-icon-li {
      position: absolute;
      left: -2.14285714em;
      width: 2.14285714em;
      top: 0.14285714em;
      text-align: center;
  }

  .wct-icon-li.wct-icon-lg {
      left: -1.85714286em;
  }



  .wct-icon-border {
      padding: .2em .25em .15em;
      border: solid 0.08em #eeeeee;
      border-radius: .1em;
  }

  .pull-right {
      float: right;
  }

  .pull-left {
      float: left;
  }

  .wct-icon.pull-left {
      margin-right: .3em;
  }

  .wct-icon.pull-right {
      margin-left: .3em;
  }



  .wct-icon-spin {
      -webkit-animation: wct-icon-spin 2s infinite linear;
      animation: wct-icon-spin 2s infinite linear;
  }

  .wct-icon-pulse {
      -webkit-animation: wct-icon-spin 1s infinite steps(8);
      animation: wct-icon-spin 1s infinite steps(8);
  }

  @-webkit-keyframes wct-icon-spin {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(359deg);
          transform: rotate(359deg);
      }
  }

  @keyframes wct-icon-spin {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(359deg);
          transform: rotate(359deg);
      }
  }



  .wct-icon-rotate-90 {
      filter: "progid: DXImageTransform.Microsoft.BasicImage(rotation=1)";
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
  }

  .wct-icon-rotate-180 {
      filter: "progid: DXImageTransform.Microsoft.BasicImage(rotation=2)";
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
  }

  .wct-icon-rotate-270 {
      filter: "progid: DXImageTransform.Microsoft.BasicImage(rotation=3)";
      -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg);
  }

  .wct-icon-flip-horizontal {
      filter: "progid: DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
      -webkit-transform: scale(-1, 1);
      -ms-transform: scale(-1, 1);
      transform: scale(-1, 1);
  }

  .wct-icon-flip-vertical {
      filter: "progid: DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
      -webkit-transform: scale(1, -1);
      -ms-transform: scale(1, -1);
      transform: scale(1, -1);
  }

  :root .wct-icon-rotate-90,
  :root .wct-icon-rotate-180,
  :root .wct-icon-rotate-270,
  :root .wct-icon-flip-horizontal,
  :root .wct-icon-flip-vertical {
      filter: none;
  }



  .wct-icon-stack {
      position: relative;
      display: inline-block;
      width: 2em;
      height: 2em;
      line-height: 2em;
      vertical-align: middle;
  }

  .wct-icon-stack-1x,
  .wct-icon-stack-2x {
      position: absolute;
      left: 0;
      width: 100%;
      text-align: center;
  }

  .wct-icon-stack-1x {
      line-height: inherit;
  }

  .wct-icon-stack-2x {
      font-size: 2em;
  }

  .wct-icon-inverse {
      color: #ffffff;
  }



  .wct-icon-detweesnoeken:before {
      content: '\f00f';
  }

  .wct-icon-menu-bewoners:before {
      content: '\f010';
  }

  .wct-icon-menu-uw-woning:before {
      content: '\f011';
  }

  .wct-icon-menu-uw-verbruik:before {
      content: '\f012';
  }

  .wct-icon-menu-documenten:before {
      content: '\f013';
  }

  .wct-icon-menu-overzicht:before {
      content: '\f014';
  }

  .wct-icon-menu-enquete:before {
      content: '\f015';
  }

  .wct-icon-menu-klachten:before {
      content: '\f016';
  }

  .wct-icon-menu-kalender:before {
      content: '\f017';
  }

  .wct-icon-activatiesleutel:before {
      content: '\f018';
  }

  .wct-icon-arrow-left:before {
      content: '\f01a';
  }

  .wct-icon-arrow-right:before {
      content: '\f01b';
  }

  .wct-icon-arrow-right-bold:before {
      content: '\f01c';
  }

  .wct-icon-arrow-left-bold:before {
      content: '\f01d';
  }

  .wct-icon-menu-uw-zorg:before {
      content: '\f01e';
  }

  .wct-icon-gasverbruik:before {
      content: '\f01f';
  }

  .wct-icon-electriciteitsverbruik:before {
      content: '\f020';
  }

  .wct-icon-stadswarmte:before {
      content: '\f021';
  }

  .wct-icon-waterverbruik:before {
      content: '\f022';
  }

  .wct-icon-radio-button-checked:before {
      content: '\f023';
  }

  .wct-icon-radio-button-unchecked:before {
      content: '\f024';
  }

  .wct-icon-check:before {
      content: '\f025';
  }

  .wct-icon-label-gebouw:before {
      content: '\f026';
  }

  .wct-icon-label-bewoner:before {
      content: '\f027';
  }

  .wct-icon-label-energie:before {
      content: '\f028';
  }

  .wct-icon-menu-informatie:before {
      content: '\f029';
  }

  .wct-icon-plus:before {
      content: '\f02a';
  }

  .wct-icon-pencil:before {
      content: '\f02c';
  }

  .wct-icon-rotate-right:before {
      content: '\f02d';
  }

  .wct-icon-rotate-left:before {
      content: '\f02e';
  }

  .wct-icon-folder-move:before {
      content: '\f02f';
  }

  .wct-icon-label-verbruik:before {
      content: '\f030';
  }

  .wct-icon-expand:before {
      content: '\f031';
  }

  .wct-icon-download:before {
      content: '\f032';
  }

  .wct-icon-upload:before {
      content: '\f033';
  }

  .wct-icon-complex:before {
      content: '\f034';
  }

  .wct-icon-blok:before {
      content: '\f035';
  }

  .wct-icon-woning:before {
      content: '\f036';
  }

  .wct-icon-filter:before {
      content: '\f037';
  }

  .wct-icon-notificatie:before {
      content: '\f038';
  }

  .wct-icon-undo:before {
      content: '\f039';
  }

  .wct-icon-mobile-phone:before {
      content: '\f03a';
  }

  .wct-icon-desktop:before {
      content: '\f03b';
  }

  .wct-icon-chat:before {
      content: '\f03c';
  }

  .wct-icon-close:before {
      content: '\f03d';
  }

  .wct-icon-mijn-meldingen:before {
      content: '\f03e';
  }

  .wct-icon-maak-melding:before {
      content: '\f03f';
  }

  .wct-icon-menu-comfort:before {
      content: '\f040';
  }

  .wct-icon-menu-tarieven:before {
      content: '\f041';
  }

  .wct-icon-menu-opname:before {
      content: '\f042';
  }

  .wct-icon-play-circle:before {
      content: '\f043';
  }

  .wct-icon-play:before {
      content: '\f044';
  }

  .wct-icon-align-center:before {
      content: '\f045';
  }

  .wct-icon-question-circle:before {
      content: '\f046';
  }

  .wct-icon-question-circle-inverted:before {
      content: '\f047';
  }

  .wct-icon-question-circle-medium:before {
      content: '\f048';
  }

  .wct-icon-question-circle-medium-inverted:before {
      content: '\f049';
  }

  .wct-icon-menu-berichten:before {
      content: '\f04a';
  }

  .wct-icon-pencil-md:before {
      content: '\f04b';
  }

  .wct-icon-upload-file:before {
      content: '\f04c';
  }

  .wct-icon-expand-table:before {
      content: '\f04d';
  }

  .wct-icon-collapse-table:before {
      content: '\f04e';
  }

  .wct-icon-download-file:before {
      content: '\f04f';
  }

  .wct-icon-person:before {
      content: '\f050';
  }

  .wct-icon-euro:before {
      content: '\f051';
  }

  .wct-icon-barchart:before {
      content: '\f052';
  }

  .wct-icon-woning-3d:before {
      content: '\f053';
  }

  .wct-icon-co2:before {
      content: '\f054';
  }

  .wct-icon-home:before {
      content: '\f055';
  }

  .wct-icon-bouwconnect:before {
      content: '\f056';
  }

  .wct-icon-bouwconnect-circled:before {
      content: '\f057';
  }


.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}
