/* @font-face {
  font-family: 'Cantarell';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Cantarell-regular/Cantarell-regular.eot');
  src: url('/fonts/Cantarell-regular/Cantarell-regular.eot?#iefix') format('embedded-opentype'),
       local('Cantarell Regular'),
       local('Cantarell-regular'),
       url('/fonts/Cantarell-regular/Cantarell-regular.woff2') format('woff2'),
       url('/fonts/Cantarell-regular/Cantarell-regular.woff') format('woff'),
       url('/fonts/Cantarell-regular/Cantarell-regular.ttf') format('truetype'),
       url('/fonts/Cantarell-regular/Cantarell-regular.svg#Cantarell') format('svg');
}
@font-face {
  font-family: 'Cantarell';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/Cantarell-700/Cantarell-700.eot');
  src: url('/fonts/Cantarell-700/Cantarell-700.eot?#iefix') format('embedded-opentype'),
       local('Cantarell Bold'),
       local('Cantarell-700'),
       url('/fonts/Cantarell-700/Cantarell-700.woff2') format('woff2'),
       url('/fonts/Cantarell-700/Cantarell-700.woff') format('woff'),
       url('/fonts/Cantarell-700/Cantarell-700.ttf') format('truetype'),
       url('/fonts/Cantarell-700/Cantarell-700.svg#Cantarell') format('svg');
}
@font-face {
  font-family: 'Cantarell';
  font-weight: 400;
  font-style: italic;
  src: url('/fonts/Cantarell-italic/Cantarell-italic.eot');
  src: url('/fonts/Cantarell-italic/Cantarell-italic.eot?#iefix') format('embedded-opentype'),
       local('Cantarell Oblique'),
       local('Cantarell-italic'),
       url('/fonts/Cantarell-italic/Cantarell-italic.woff2') format('woff2'),
       url('/fonts/Cantarell-italic/Cantarell-italic.woff') format('woff'),
       url('/fonts/Cantarell-italic/Cantarell-italic.ttf') format('truetype'),
       url('/fonts/Cantarell-italic/Cantarell-italic.svg#Cantarell') format('svg');
}
@font-face {
  font-family: 'Cantarell';
  font-weight: 700;
  font-style: italic;
  src: url('/fonts/Cantarell-700italic/Cantarell-700italic.eot');
  src: url('/fonts/Cantarell-700italic/Cantarell-700italic.eot?#iefix') format('embedded-opentype'),
       local('Cantarell Bold Oblique'),
       local('Cantarell-700italic'),
       url('/fonts/Cantarell-700italic/Cantarell-700italic.woff2') format('woff2'),
       url('/fonts/Cantarell-700italic/Cantarell-700italic.woff') format('woff'),
       url('/fonts/Cantarell-700italic/Cantarell-700italic.ttf') format('truetype'),
       url('/fonts/Cantarell-700italic/Cantarell-700italic.svg#Cantarell') format('svg');
}
@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/Open-Sans-300/opensans-light-webfont.woff2') format('woff2'),
       url('/fonts/Open-Sans-300/opensans-light-webfont.woff') format('woff'),
       url('/fonts/Open-Sans-300/opensans-light-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/Open-Sans-300/opensans-lightitalic-webfont.woff2') format('woff2'),
       url('/fonts/Open-Sans-300/opensans-lightitalic-webfont.woff') format('woff'),
       url('/fonts/Open-Sans-300/opensans-lightitalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Open-Sans-regular/Open-Sans-regular.eot');
  src: url('/fonts/Open-Sans-regular/Open-Sans-regular.eot?#iefix') format('embedded-opentype'),
       local('Open Sans'),
       local('Open-Sans-regular'),
       url('/fonts/Open-Sans-regular/Open-Sans-regular.woff2') format('woff2'),
       url('/fonts/Open-Sans-regular/Open-Sans-regular.woff') format('woff'),
       url('/fonts/Open-Sans-regular/Open-Sans-regular.ttf') format('truetype'),
       url('/fonts/Open-Sans-regular/Open-Sans-regular.svg#OpenSans') format('svg');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: normal;
  src: url('/fonts/Open-Sans-600/Open-Sans-600.eot');
  src: url('/fonts/Open-Sans-600/Open-Sans-600.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Semibold'),
       local('Open-Sans-600'),
       url('/fonts/Open-Sans-600/Open-Sans-600.woff2') format('woff2'),
       url('/fonts/Open-Sans-600/Open-Sans-600.woff') format('woff'),
       url('/fonts/Open-Sans-600/Open-Sans-600.ttf') format('truetype'),
       url('/fonts/Open-Sans-600/Open-Sans-600.svg#OpenSans') format('svg');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/Open-Sans-700/Open-Sans-700.eot');
  src: url('/fonts/Open-Sans-700/Open-Sans-700.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Bold'),
       local('Open-Sans-700'),
       url('/fonts/Open-Sans-700/Open-Sans-700.woff2') format('woff2'),
       url('/fonts/Open-Sans-700/Open-Sans-700.woff') format('woff'),
       url('/fonts/Open-Sans-700/Open-Sans-700.ttf') format('truetype'),
       url('/fonts/Open-Sans-700/Open-Sans-700.svg#OpenSans') format('svg');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  font-style: normal;
  src: url('/fonts/Open-Sans-800/Open-Sans-800.eot');
  src: url('/fonts/Open-Sans-800/Open-Sans-800.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Extrabold'),
       local('Open-Sans-800'),
       url('/fonts/Open-Sans-800/Open-Sans-800.woff2') format('woff2'),
       url('/fonts/Open-Sans-800/Open-Sans-800.woff') format('woff'),
       url('/fonts/Open-Sans-800/Open-Sans-800.ttf') format('truetype'),
       url('/fonts/Open-Sans-800/Open-Sans-800.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Kadwa';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Kadwa-regular/Kadwa-regular.eot');
  src: url('/fonts/Kadwa-regular/Kadwa-regular.eot?#iefix') format('embedded-opentype'),
       local('Kadwa'),
       local('Kadwa-regular'),
       url('/fonts/Kadwa-regular/Kadwa-regular.woff2') format('woff2'),
       url('/fonts/Kadwa-regular/Kadwa-regular.woff') format('woff'),
       url('/fonts/Kadwa-regular/Kadwa-regular.ttf') format('truetype'),
       url('/fonts/Kadwa-regular/Kadwa-regular.svg#Kadwa') format('svg');
}
@font-face {
  font-family: 'Kadwa';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/Kadwa-700/Kadwa-700.eot');
  src: url('/fonts/Kadwa-700/Kadwa-700.eot?#iefix') format('embedded-opentype'),
  local('Kadwa Bold'),
  local('Kadwa-700'),
  url('/fonts/Kadwa-700/Kadwa-700.woff2') format('woff2'),
  url('/fonts/Kadwa-700/Kadwa-700.woff') format('woff'),
  url('/fonts/Kadwa-700/Kadwa-700.ttf') format('truetype'),
  url('/fonts/Kadwa-700/Kadwa-700.svg#Kadwa') format('svg');
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-300/roboto-light-webfont.woff2') format('woff2'),
       url('/fonts/Roboto-300/roboto-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-300italic/roboto-light-webfont.woff2') format('woff2'),
       url('/fonts/Roboto-300italic/roboto-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Roboto-regular/Roboto-regular.eot');
  src: url('/fonts/Roboto-regular/Roboto-regular.eot?#iefix') format('embedded-opentype'),
       local('Roboto'),
       local('Roboto-regular'),
       url('/fonts/Roboto-regular/Roboto-regular.woff2') format('woff2'),
       url('/fonts/Roboto-regular/Roboto-regular.woff') format('woff'),
       url('/fonts/Roboto-regular/Roboto-regular.ttf') format('truetype'),
       url('/fonts/Roboto-regular/Roboto-regular.svg#Roboto') format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/Roboto-700/Roboto-700.eot');
  src: url('/fonts/Roboto-700/Roboto-700.eot?#iefix') format('embedded-opentype'),
       local('Roboto Bold'),
       local('Roboto-700'),
       url('/fonts/Roboto-700/Roboto-700.woff2') format('woff2'),
       url('/fonts/Roboto-700/Roboto-700.woff') format('woff'),
       url('/fonts/Roboto-700/Roboto-700.ttf') format('truetype'),
       url('/fonts/Roboto-700/Roboto-700.svg#Roboto') format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: italic;
  src: url('/fonts/Roboto-italic/Roboto-italic.eot');
  src: url('/fonts/Roboto-italic/Roboto-italic.eot?#iefix') format('embedded-opentype'),
       local('Roboto Italic'),
       local('Roboto-italic'),
       url('/fonts/Roboto-italic/Roboto-italic.woff2') format('woff2'),
       url('/fonts/Roboto-italic/Roboto-italic.woff') format('woff'),
       url('/fonts/Roboto-italic/Roboto-italic.ttf') format('truetype'),
       url('/fonts/Roboto-italic/Roboto-italic.svg#Roboto') format('svg');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: italic;
  src: url('/fonts/Roboto-700italic/Roboto-700italic.eot');
  src: url('/fonts/Roboto-700italic/Roboto-700italic.eot?#iefix') format('embedded-opentype'),
       local('Roboto Bold Italic'),
       local('Roboto-700italic'),
       url('/fonts/Roboto-700italic/Roboto-700italic.woff2') format('woff2'),
       url('/fonts/Roboto-700italic/Roboto-700italic.woff') format('woff'),
       url('/fonts/Roboto-700italic/Roboto-700italic.ttf') format('truetype'),
       url('/fonts/Roboto-700italic/Roboto-700italic.svg#Roboto') format('svg');
}

@font-face {
    font-family: 'Patua One';
    src: url('/fonts/Patua-One/patuaone-regular-webfont.woff2') format('woff2'),
         url('/fonts/Patua-One/patuaone-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

} */

@font-face {
    font-family: 'Source Sans Pro';
    src: url('fonts/Source-Sans-Pro/sourcesanspro-light-webfont.woff2') format('woff2'),
         url('fonts/Source-Sans-Pro/sourcesanspro-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Source Sans Pro';
    src: url('fonts/Source-Sans-Pro/sourcesanspro-lightitalic-webfont.woff2') format('woff2'),
         url('fonts/Source-Sans-Pro/sourcesanspro-lightitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Source Sans Pro';
    src: url('fonts/Source-Sans-Pro/sourcesanspro-regular-webfont.woff2') format('woff2'),
         url('fonts/Source-Sans-Pro/sourcesanspro-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Source Sans Pro';
    src: url('fonts/Source-Sans-Pro/sourcesanspro-italic-webfont.woff2') format('woff2'),
         url('fonts/Source-Sans-Pro/sourcesanspro-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Source Sans Pro';
    src: url('fonts/Source-Sans-Pro/sourcesanspro-semibold-webfont.woff2') format('woff2'),
         url('fonts/Source-Sans-Pro/sourcesanspro-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Source Sans Pro';
    src: url('fonts/Source-Sans-Pro/sourcesanspro-semibolditalic-webfont.woff2') format('woff2'),
         url('fonts/Source-Sans-Pro/sourcesanspro-semibolditalic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Source Sans Pro';
    src: url('fonts/Source-Sans-Pro/sourcesanspro-bold-webfont.woff2') format('woff2'),
         url('fonts/Source-Sans-Pro/sourcesanspro-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Source Sans Pro';
    src: url('fonts/Source-Sans-Pro/sourcesanspro-bolditalic-webfont.woff2') format('woff2'),
         url('fonts/Source-Sans-Pro/sourcesanspro-bolditalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Source Sans Pro';
    src: url('fonts/Source-Sans-Pro/sourcesanspro-black-webfont.woff2') format('woff2'),
         url('fonts/Source-Sans-Pro/sourcesanspro-black-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Source Sans Pro';
    src: url('fonts/Source-Sans-Pro/sourcesanspro-blackitalic-webfont.woff2') format('woff2'),
         url('fonts/Source-Sans-Pro/sourcesanspro-blackitalic-webfont.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('fonts/Titillium-Web/TitilliumWeb-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('fonts/Titillium-Web/TitilliumWeb-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('fonts/Titillium-Web/TitilliumWeb-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('fonts/Titillium-Web/TitilliumWeb-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('fonts/Titillium-Web/TitilliumWeb-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('fonts/Titillium-Web/TitilliumWeb-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('fonts/Titillium-Web/TitilliumWeb-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('fonts/Titillium-Web/TitilliumWeb-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('fonts/Titillium-Web/TitilliumWeb-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('fonts/Titillium-Web/TitilliumWeb-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('fonts/Titillium-Web/TitilliumWeb-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}


/* @font-face {
     font-family: 'Glyphicons Halflings';
     src: url('/fonts/Bootstrap-icons/glyphicons-halflings-regular.woff2') format('woff2'),
          url('/fonts/Bootstrap-icons/glyphicons-halflings-regular.woff') format('woff');
   }

@font-face {
     font-family: 'Graphik';
     src: url('/fonts/Graphik/Graphik-Light-Web.woff2') format('woff2');
     font-weight: 300;
}
@font-face {
     font-family: 'Graphik';
     src: url('/fonts/Graphik/Graphik-Regular-Web.woff2') format('woff2');
     font-weight: 400;
}
@font-face {
     font-family: 'Graphik';
     src: url('/fonts/Graphik/Graphik-Medium-Web.woff2') format('woff2');
     font-weight: 600;
}

@font-face {
     font-family: 'Barlow';
     src: url('/fonts/Barlow/Barlow-Regular .woff2') format('woff2');
     font-weight: 400;
}
@font-face {
     font-family: 'Barlow';
     src: url('/fonts/Barlow/barlow-italic-webfont.woff2') format('woff2'),
          url('/fonts/Barlow/barlow-italic-webfont.woff') format('woff');
     font-weight: normal;
     font-style: italic;
 } */
