/* You can add global styles to this file, and also import other style files */

// This styling has to be moved to the login component if neccesary

//@use '~@angular/material' as mat;

// this mixin from angular material must be included only once
//@include mat.core();

html {
	font-size: 10px;
	font-family: "Source Sans Pro", Helvetica, Arial;
	// .mat-select-value, mat-form-field {
	// 	font-family: "Source Sans Pro", Helvetica, Arial !important;
	// 	font-size: 1.4rem !important;
	// }
	// .mat-slide-toggle-bar {
	// 	background-color: #1aabff !important;
	// }
	// .mat-slide-toggle-thumb {
	// 	background-color: #0078be !important;
	// }
	// .mat-checkbox-checked {
	// 	.mat-checkbox-background {
	// 		background-color: #0078be !important;
	// 	}
	// }
	// .mat-checkbox-label {
	// 	font-family: "Source Sans Pro", Helvetica, Arial !important;
	// 	font-size: 1.4rem !important;
	// }
}
